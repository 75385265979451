/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component, ViewEncapsulation, OnDestroy } from '@angular/core';
import { YukkApi } from 'src/app/service/yukkapi.service';
import { Router, ActivatedRoute } from '@angular/router';
import { Observable, concat, Subject } from 'rxjs';
import { toArray, takeUntil } from 'rxjs/operators';
import * as moment from 'moment';
import * as d3 from 'd3';
import { StockChart } from 'angular-highcharts';
import { RoutingService } from 'src/app/service/routing.service';
import { AuthService } from 'src/app/service/auth.service';

/**
 * This component is used 4 display the multichart it use multiple yAxis overlayed
 * Each serie have a random color and only the fisrt 3 are visible by default
 */

@Component({
  encapsulation: ViewEncapsulation.None,
  selector: 'app-query-chart',
  templateUrl: './query-chart.component.html',
  styleUrls: ['./query-chart.component.scss'],
})
export class QueryChartComponent implements OnDestroy {
  protected ngUnsubscribe: Subject<void> = new Subject<void>();

  loading: boolean;
  options: any;
  series: any = [];
  navigatorData: any = [];
  params: any;
  keyid: any;
  stockChart: StockChart;
  stockChartRef: any;
  colors = [
    '#feb236',
    '#6b5b95',
    '#d64161',
    '#ff7b25',
    '#878f99',
    '#b5e7a0',
    '#eca1a6',
    '#bdcebe',
  ];
  colorPalette = ['#00fec2', '#18ebff', '#b4bdff', '#a351fe', '#fb00e6'];
  previousValue: any;
  period: number;
  titolo$: Observable<any>;

  /**
   * In the constuector we subscribe to the parameteres and check if some one of that changed
   * If yes we request data and start generate the series
   */
  constructor(
    private route: ActivatedRoute,
    private yukkApi: YukkApi,
    private router: Router,
    public routing: RoutingService,
    public auth: AuthService,
  ) {
    this.route.queryParams.subscribe((params) => {
      this.params = params;
      this.period = params.period ? parseInt(params.period, 10) : 7;
      const reflowParams = ['reflow'];
      if (this.routing.reFresh(this.params, this.previousValue, reflowParams)) {
        if (this.stockChartRef) {
          setTimeout(() => {
            this.stockChartRef.source.value.reflow();
          }, 500);
        }
      }
      if (params.updatePayload) {
        this.auth.payloadEntities = [];
      }
      if (
        routing.reFresh(params, this.previousValue, [
          'update',
          'theme',
          'id',
          'time',
          'period',
          'lang',
          'feed',
          'categories',
          'continents',
          'countries',
          'factuality',
          'temporality',
          'ranks',
          'panels',
        ])
      ) {
        // abort unwanted HTTP requests
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
        this.ngUnsubscribe = new Subject();

        this.loading = true;
        this.titolo$ = this.yukkApi.isentiment(this.params, false);
        if (
          !(auth.scorelabSettings.defaultScore === 'sentiment') &&
          !(this.auth.scorelabSettings.defaultScore === 'credit_risk') &&
          !(this.routing.isQuery() || this.routing.isSearch())
        ) {
          const content = this.auth.folio ? this.auth.folio.content : [];
          const result = { multi_trend_radar: [] };
          this.yukkApi.getEntitiesInfo(content).subscribe((res) => {
            this.yukkApi
              .scoresTimeSeries('score', this.params, 'izoom')
              .subscribe((result2) => {
                const requestsList = [];
                const chunkSize = 100;
                for (let i = 0; i < content.length; i += chunkSize) {
                  requestsList.push(
                    this.yukkApi.scoresTimeSeries(
                      'score',
                      Object.assign({}, this.params, {
                        scoreForPortfolio: true,
                        itemized: true,
                        custom_payload: content.slice(i, i + chunkSize),
                      }),
                      'chart',
                    ),
                  );
                }
                // const observables = (auth.scorelabSettings.defaultScore === 'credit_risk') ? (concat(...requestsList).pipe(toArray())) : (forkJoin(requestsList));
                concat(...requestsList)
                  .pipe(toArray())
                  .pipe(takeUntil(this.ngUnsubscribe))
                  .subscribe((result4: any[]) => {
                    const result3 = result4.flat();
                    const data = result2.score_ts.map((item) => {
                      return [
                        this.geTime(moment(item.date).format('YYYY-MM-DD')),
                        item.score !== null
                          ? Number(item.score.toFixed(2))
                          : null,
                      ];
                    });
                    this.navigatorData = data;
                    this.series = [];
                    // const timeframeLength = result3[0].score_ts.length;
                    const dates = [];
                    result3[0].score_ts.forEach((item) => {
                      dates.push(item.date);
                    });
                    const portfolioSeries = result2.score_ts.filter((item) =>
                      dates.includes(item.date),
                    );
                    result.multi_trend_radar.push({
                      entity: {
                        alpha_id: 'portfolio',
                        type: 'portfolio',
                        name: 'Portfolio',
                      },
                      quotient: result2.score_ts_last_score.score,
                      series: portfolioSeries,
                      // series: result2.score_ts.slice(-timeframeLength)
                    });
                    content.forEach((item) => {
                      const result3item = result3.filter((el) => {
                        return el.entity_info.compound_key === item;
                      })[0];
                      result.multi_trend_radar.push({
                        entity: {
                          alpha_id: res[item].alpha_id,
                          type: res[item].type,
                          name: res[item].name,
                        },
                        quotient: result3item?.score_ts_last_score?.score,
                        series: result3item?.score_ts,
                      });
                    });
                    result.multi_trend_radar.forEach((item, index) => {
                      const data2 = item.series?.map((point) => {
                        return [
                          this.geTime(moment(point.date).format('YYYY-MM-DD')),
                          point.score !== null
                            ? Number(point.score.toFixed(2))
                            : null,
                        ];
                      });
                      const quotient = {
                        type: 'spline',
                        // name: item.entity.type === 'search_query' ? 'Query' : item.entity.name,
                        name: item.entity.name,
                        custom: {
                          compound_key: item.entity.compound_key
                            ? item.entity.compound_key
                            : null,
                        },
                        lineWidth: 2,
                        yAxis: 0,
                        data: data2,
                        visible: index < 3 ? true : false,
                        showInLegend: true,
                        color: this.getPaletteColor(index),
                      };
                      this.series.push(quotient);
                    });
                    this.initChart();
                    if (this.stockChartRef) {
                      if (this.stockChartRef) {
                        setTimeout(() => {
                          this.stockChartRef.source.value.reflow();
                        }, 1000);
                      }
                    }
                  });
              });
          });
        } else if (this.routing.isQuery() || this.routing.isSearch()) {
          console.log(this.params);

          this.yukkApi.trendradar(this.params).subscribe((result) => {
            console.log(this.params);

            this.yukkApi
              .scoresTimeSeries('sentiment', this.params, 'izoom')
              .subscribe((result2) => {
                const data = result2.quotient_ts.map((item) => {
                  const date = moment.utc(item.date).valueOf();
                  return [date, item.quotient];
                });
                this.navigatorData = data;
                this.series = [];
                const res = { multi_trend_radar: [] };
                if (this.auth.iquery && this.routing.isQuery()) {
                  result.forEach((item, index) => {
                    const item2 = { entity: { name: '' } };
                    if (index === 0) {
                      item2.entity.name = this.auth.query.name;
                    } else {
                      item2.entity.name = this.auth.iquery[index - 1].name;
                    }
                    item2['series'] = result[index]?.quotient_ts;
                    res.multi_trend_radar.push(item2);
                  });
                  res.multi_trend_radar.forEach((item, index) => {
                    const data2 = item.series?.map((point) => {
                      return [
                        this.geTime(moment(point.date).format('YYYY-MM-DD')),
                        point.quotient !== null
                          ? Number(point.quotient.toFixed(2))
                          : null,
                      ];
                    });
                    const quotient = {
                      type: 'spline',
                      // name: item.entity.type === 'search_query' ? 'Query' : item.entity.name,
                      name: item.entity.name,
                      custom: {
                        compound_key: item.entity.compound_key
                          ? item.entity.compound_key
                          : null,
                      },
                      lineWidth: 2,
                      yAxis: 0,
                      data: data2,
                      visible: index < 3 ? true : false,
                      showInLegend: true,
                      color: this.getPaletteColor(index),
                    };
                    this.series.push(quotient);
                  });
                  this.initChart();
                  if (this.stockChartRef) {
                    if (this.stockChartRef) {
                      setTimeout(() => {
                        this.stockChartRef.source.value.reflow();
                      }, 1000);
                    }
                  }
                } else if (this.routing.isQuery() || this.routing.isSearch()) {
                  const content = [];
                  this.auth.query?.query?.groups.forEach((group) => {
                    group.filters.forEach((filter) => {
                      if (filter.field === 'entity') {
                        content.push(filter.value);
                      }
                    });
                  });
                  if (content.length > 0) {
                    res.multi_trend_radar.push({
                      entity: {
                        name: this.routing.isSearch()
                          ? 'Search Query'
                          : this.auth.query.name,
                      },
                      series: result.quotient_ts,
                    });
                    this.yukkApi.getEntitiesInfo(content).subscribe((res2) => {
                      const requestsList = [];
                      const chunkSize = 100;
                      for (let i = 0; i < content.length; i += chunkSize) {
                        requestsList.push(
                          this.yukkApi.scoresTimeSeries(
                            'sentiment',
                            Object.assign({}, this.params, {
                              scoreForPortfolio: true,
                              itemized: true,
                              custom_payload: content.slice(i, i + chunkSize),
                            }),
                            'chart',
                          ),
                        );
                      }
                      // const observables = (auth.scorelabSettings.defaultScore === 'credit_risk') ? (concat(...requestsList).pipe(toArray())) : (forkJoin(requestsList));
                      concat(...requestsList)
                        .pipe(toArray())
                        .pipe(takeUntil(this.ngUnsubscribe))
                        .subscribe((result4Arg: any[]) => {
                          const result4 = result4Arg.map(
                            (el) => el['quotient_ts_itemized'],
                          );
                          const result3 = result4.flat();
                          content.forEach((item) => {
                            const result3item = result3.filter((el) => {
                              return el.entity_info.compound_key === item;
                            })[0];
                            res.multi_trend_radar.push({
                              entity: {
                                alpha_id: res2[item].alpha_id,
                                type: res2[item].type,
                                name: res2[item].name,
                              },
                              series: result3item?.quotient_ts,
                            });
                          });
                          res.multi_trend_radar.forEach((item, index) => {
                            const data2 = item.series?.map((point) => {
                              return [
                                this.geTime(
                                  moment(point.date).format('YYYY-MM-DD'),
                                ),
                                point.quotient !== null
                                  ? Number(point.quotient.toFixed(2))
                                  : null,
                              ];
                            });
                            const quotient = {
                              type: 'spline',
                              // name: item.entity.type === 'search_query' ? 'Query' : item.entity.name,
                              name: item.entity.name,
                              custom: {
                                compound_key: item.entity.compound_key
                                  ? item.entity.compound_key
                                  : null,
                              },
                              lineWidth: 2,
                              yAxis: 0,
                              data: data2,
                              visible: index < 3 ? true : false,
                              showInLegend: true,
                              color: this.getPaletteColor(index),
                            };
                            this.series.push(quotient);
                          });
                          this.initChart();
                          if (this.stockChartRef) {
                            if (this.stockChartRef) {
                              setTimeout(() => {
                                this.stockChartRef.source.value.reflow();
                              }, 1000);
                            }
                          }
                        });
                    });
                  } else {
                    res.multi_trend_radar.push({
                      entity: {
                        name: this.routing.isSearch()
                          ? 'Search Query'
                          : this.auth.query.name,
                      },
                      series: result.quotient_ts,
                    });
                    res.multi_trend_radar.forEach((item, index) => {
                      const data2 = item.series?.map((point) => {
                        return [
                          this.geTime(moment(point.date).format('YYYY-MM-DD')),
                          point.quotient !== null
                            ? Number(point.quotient.toFixed(2))
                            : null,
                        ];
                      });
                      const quotient = {
                        type: 'spline',
                        // name: item.entity.type === 'search_query' ? 'Query' : item.entity.name,
                        name: item.entity.name,
                        custom: {
                          compound_key: item.entity.compound_key
                            ? item.entity.compound_key
                            : null,
                        },
                        lineWidth: 2,
                        yAxis: 0,
                        data: data2,
                        visible: index < 3 ? true : false,
                        showInLegend: true,
                        color: this.getPaletteColor(index),
                      };
                      this.series.push(quotient);
                    });
                    this.initChart();
                    if (this.stockChartRef) {
                      if (this.stockChartRef) {
                        setTimeout(() => {
                          this.stockChartRef.source.value.reflow();
                        }, 1000);
                      }
                    }
                  }
                }
              });
          });
        } else {
          const content = this.auth.folio ? this.auth.folio.content : [];
          const result = { multi_trend_radar: [] };
          this.yukkApi.getEntitiesInfo(content).subscribe((res) => {
            this.yukkApi
              .scoresTimeSeries('sentiment', this.params, 'izoom')
              .subscribe((result2) => {
                const requestsList = [];
                const chunkSize = 100;
                for (let i = 0; i < content.length; i += chunkSize) {
                  requestsList.push(
                    this.yukkApi.scoresTimeSeries(
                      'sentiment',
                      Object.assign({}, this.params, {
                        scoreForPortfolio: true,
                        itemized: true,
                        custom_payload: content.slice(i, i + chunkSize),
                      }),
                      'chart',
                    ),
                  );
                }
                // const observables = (auth.scorelabSettings.defaultScore === 'credit_risk') ? (concat(...requestsList).pipe(toArray())) : (forkJoin(requestsList));
                concat(...requestsList)
                  .pipe(toArray())
                  .pipe(takeUntil(this.ngUnsubscribe))
                  .subscribe((result4Arg) => {
                    const result4 = result4Arg.map(
                      (el) => el['quotient_ts_itemized'],
                    );
                    const result3 = result4.flat();
                    const data = result2.quotient_ts.map((item) => {
                      const date = moment.utc(item.date).valueOf();
                      return [date, item.quotient];
                    });
                    this.navigatorData = data;
                    this.series = [];
                    // const timeframeLength = result3[0].quotient_ts.length;
                    const dates = [];
                    result3[0].quotient_ts.forEach((item) => {
                      dates.push(item.date);
                    });
                    const portfolioSeries = result2.quotient_ts.filter((item) =>
                      dates.includes(item.date),
                    );
                    result.multi_trend_radar.push({
                      entity: {
                        alpha_id: 'portfolio',
                        type: 'portfolio',
                        name: 'Portfolio',
                      },
                      series: portfolioSeries,
                      // series: result2.quotient_ts.slice(-timeframeLength)
                    });
                    content.forEach((item) => {
                      const result3item = result3.filter((el) => {
                        return el.entity_info.compound_key === item;
                      })[0];
                      result.multi_trend_radar.push({
                        entity: {
                          alpha_id: res[item].alpha_id,
                          type: res[item].type,
                          name: res[item].name,
                        },
                        series: result3item?.quotient_ts,
                      });
                    });
                    result.multi_trend_radar.forEach((item, index) => {
                      const data2 = item.series?.map((point) => {
                        return [
                          this.geTime(moment(point.date).format('YYYY-MM-DD')),
                          point.quotient !== null
                            ? Number(point.quotient.toFixed(2))
                            : null,
                        ];
                      });
                      const quotient = {
                        type: 'spline',
                        // name: item.entity.type === 'search_query' ? 'Query' : item.entity.name,
                        name: item.entity.name,
                        custom: {
                          compound_key: item.entity.compound_key
                            ? item.entity.compound_key
                            : null,
                        },
                        lineWidth: 2,
                        yAxis: 0,
                        data: data2,
                        visible: index < 3 ? true : false,
                        showInLegend: true,
                        color: this.getPaletteColor(index),
                      };
                      this.series.push(quotient);
                    });
                    this.initChart();
                    if (this.stockChartRef) {
                      if (this.stockChartRef) {
                        setTimeout(() => {
                          this.stockChartRef.source.value.reflow();
                        }, 1000);
                      }
                    }
                  });
              });
          });
        }
      }
      this.previousValue = params;
    });
  }

  ngOnDestroy() {
    // abort unwanted HTTP requests
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  geTime(value) {
    return moment.utc(value).valueOf();
  }

  /**
   * generate random color
   */
  getRandomColor() {
    const letters = '0123456789ABCDEF';
    let color = '#';
    for (let i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
  }

  /**
   * get color from the colors palette
   */
  getPaletteColor(index) {
    let value;
    if (index === 0) {
      value = 0;
    } else if (index === 1) {
      value = 1;
    } else if (index === 2) {
      value = 0.5;
    } else if (index === 3) {
      value = 0.25;
    } else if (index === 4) {
      value = 0.75;
    } else {
      value = Math.random();
    }
    const pickColor = d3
      .scaleLinear<string>()
      .domain([0, 0.25, 0.5, 0.75, 1])
      .range(this.colorPalette as any);

    return pickColor(value);
  }

  /**
   * get the time range
   */
  getRange() {
    const fromto = this.yukkApi.chartFromTo('7');
    const range = {
      min: moment.utc(fromto.date1).valueOf(),
      max: moment.utc(fromto.date2).valueOf(),
    };
    return range;
  }

  onAfterSetExtremes(event) {
    if (event.trigger === 'zoom' || event.trigger === 'navigator') {
      const time =
        moment(event.min).format('YYYY-MM-DD') +
        '--' +
        moment(event.max).format('YYYY-MM-DD');
      // if (period > 30) {
      //   this.period = 7
      //   if (period > 90) { this.period = 14 }
      // }
      this.router.navigate([], {
        queryParams: {
          time: time,
          newsday: null,
          period: this.period,
        },
        queryParamsHandling: 'merge',
      });
    }
  }

  onLegendItemClick() {
    if (this.routing.isFolio() && this.routing.isChart2()) {
      setTimeout(() => {
        const visibleEntities = [];
        const portfolioVisible =
          this.stockChartRef.source.value.series[0].visible;
        if (!portfolioVisible) {
          this.stockChartRef.source.value.series.forEach((item) => {
            if (item.visible && item.userOptions.custom.compound_key) {
              visibleEntities.push(item.userOptions.custom.compound_key);
            }
          });
          this.auth.payloadEntities = visibleEntities;
          this.router.navigate([], {
            queryParams: { updatePayload: Math.random() },
            queryParamsHandling: 'merge',
            replaceUrl: true,
          });
        } else {
          this.router.navigate([], {
            queryParams: { updatePayload: Math.random() },
            queryParamsHandling: 'merge',
            replaceUrl: true,
          });
        }
      }, 200);
    }
  }

  /**
   * initialization of the chart
   */
  initChart() {
    let fromScorelab = false;
    if (
      !(this.auth.scorelabSettings.defaultScore === 'sentiment') &&
      !(this.auth.scorelabSettings.defaultScore === 'credit_risk') &&
      !(this.routing.isQuery() || this.routing.isSearch())
    ) {
      fromScorelab = true;
    }
    this.options = {
      chart: {
        backgroundColor: 'transparent',
        zoomType: 'x',
        alignTicks: false,
      },
      credits: { enabled: false },
      exporting: {
        enabled: true,
        buttons: {
          contextButton: {
            symbol: 'menuball',
            width: 18,
            height: 18,
            symbolX: 9,
            symbolY: 9,
            symbolSize: 10,
            symbolStrokeWidth: 2,
            theme: {
              fill: 'none',
              stroke: 'none',
            },
          },
        },
      },
      rangeSelector: {
        enabled: false,
        selected: 1,
        buttonTheme: {
          fill: '#505053',
          stroke: '#000000',
          style: {
            color: '#CCC',
          },
          states: {
            hover: {
              fill: '#707073',
              stroke: '#000000',
              style: {
                color: 'white',
              },
            },
            select: {
              fill: '#000003',
              stroke: '#000000',
              style: {
                color: 'white',
              },
            },
          },
        },
        inputBoxBorderColor: '#505053',
        inputStyle: {
          backgroundColor: '#333',
          color: 'silver',
        },
        labelStyle: {
          color: 'silver',
        },
      },
      legend: {
        enabled: true,
        maxHeight: 140,
        itemStyle: {
          color:
            localStorage.getItem('theme') === 'light' ||
            this.params.theme === 'light'
              ? '#606063'
              : '#E0E0E3',
        },
        itemHoverStyle: {
          color:
            localStorage.getItem('theme') === 'light' ||
            this.params.theme === 'light'
              ? '#606063'
              : '#E0E0E3',
        },
        itemHiddenStyle: {
          color:
            localStorage.getItem('theme') === 'light' ||
            this.params.theme === 'light'
              ? '#E0E0E3'
              : '#606063',
        },
        navigation: {
          style: {
            color:
              localStorage.getItem('theme') === 'light' ||
              this.params.theme === 'light'
                ? '#606063'
                : '#E0E0E3',
          },
        },
      },
      tooltip: {
        split: true,
        backgroundColor: 'rgba(0,0,0, 0.8)',
        pointFormatter: function () {
          if (fromScorelab) {
            return this.series.name + ' ' + this.y;
          } else {
            const percent = this.y * 100;
            return this.series.name + ' ' + percent.toFixed(0) + '%';
          }
        },
        style: {
          color: 'rgba(255,255,255, 1)',
        },
      },
      navigator: {
        enabled: true,
        handles: {
          backgroundColor: '#212121',
          borderColor: '#AAA',
        },
        outlineColor: 'transparent',
        outlineWidth: 0,
        maskFill: 'rgba(0,0,0,0.1)',
        series: {
          color: '#525252',
          lineColor: '#727272',
          data: this.navigatorData,
        },
        xAxis: {
          gridLineColor: '#525252',
        },
      },
      scrollbar: {
        enabled: false,
        barBackgroundColor: 'transparent',
        barBorderColor: '#808083',
        buttonArrowColor: '#CCC',
        buttonBackgroundColor: '#606063',
        buttonBorderColor: '#606063',
        rifleColor: 'transparent',
        trackBackgroundColor: 'transparent',
        trackBorderColor: '#404043',
      },
      xAxis: {
        type: 'datetime',
        gridLineColor: '#707073',
        labels: {
          style: {
            color: '#E0E0E3',
          },
        },
        lineColor: '#707073',
        minorGridLineColor: '#505053',
        tickColor: '#707073',
        title: {
          style: {
            color: '#A0A0A3',
          },
        },
        maxRange: this.routing.isDev()
          ? undefined
          : this.auth.internalCockpitSettings.maxTimeframe * 24 * 3600000,
        events: {
          afterSetExtremes: (event) => this.onAfterSetExtremes(event),
        },
      },
      yAxis: [
        {
          opposite: true,
          labels: {
            formatter: function () {
              if (fromScorelab) {
                return this.value;
              } else {
                return Math.round(this.value * 100) + '%';
              }
            },
            align: 'left',
            style: {
              color: '#E0E0E3',
            },
          },
          title: {
            text: '',
            style: {
              color: '#A0A0A3',
            },
          },
          lineWidth: 2,
          gridLineColor: '#2D2D2D',
          lineColor: 'transparent',
        },
      ],
      plotOptions: {
        series: {
          events: {
            legendItemClick: () => this.onLegendItemClick(),
          },
        },
      },
      series: this.series,
    };
    this.stockChart = new StockChart(this.options);
    this.stockChartRef = this.stockChart.ref$;
    this.stockChartRef.subscribe((res) => {
      let startDate = '';
      let endDate = '';
      let highchartsTextObject;
      // const textWidth = 150;
      const calculatePosition = (
        navLeft,
        navRight,
        textSize,
        boxSize,
        boxPadding,
      ) => {
        const positionLeft = Math.round((navLeft + navRight - textSize) * 0.5);
        const positionRight = Math.round((navLeft + navRight + textSize) * 0.5);
        if (positionLeft < 0) {
          return 0;
        } else if (positionRight > boxSize + boxPadding) {
          return boxSize + boxPadding - textSize;
        } else {
          return positionLeft;
        }
      };
      const calculateDates = (dateMin, dateMax, navMin, navMax, navSize) => {
        return {
          start: Math.ceil(dateMin + ((dateMax - dateMin) * navMin) / navSize),
          end: Math.floor(dateMin + ((dateMax - dateMin) * navMax) / navSize),
        };
      };
      const navContainer = document.getElementsByClassName(
        'highcharts-navigator',
      )[0];
      navContainer.addEventListener(
        'mousemove',
        () => {
          if (
            highchartsTextObject &&
            !(Object.keys(highchartsTextObject).length === 0)
          ) {
            highchartsTextObject.destroy();
          }
          const dateRange = calculateDates(
            res.navigator.xAxis.min,
            res.navigator.xAxis.max,
            res.navigator.zoomedMin,
            res.navigator.zoomedMax,
            res.navigator.size,
          );
          startDate = moment(dateRange.start).format('DD.MM.YYYY');
          endDate = moment(dateRange.end).format('DD.MM.YYYY');
          highchartsTextObject = res.renderer
            .text(startDate + ' - ' + endDate, 0, 0, 'useHTML')
            .attr({ id: 'navTooltip' })
            .css({ color: 'transparent' })
            .add();
          const tooltipContainer = document.getElementById('navTooltip');
          const tooltipContainerWidth = tooltipContainer.offsetWidth;
          highchartsTextObject.destroy();
          const textPositionX = calculatePosition(
            res.navigator.handles[0].translateX,
            res.navigator.handles[1].translateX,
            tooltipContainerWidth,
            res.navigator.size,
            res.navigator.left,
          );
          const themeColor =
            localStorage.getItem('theme') === 'light' ||
            this.params.theme === 'light'
              ? '0, 0, 0'
              : '255, 255, 255';
          const color = 'rgba(' + themeColor + ', 0.4)';
          highchartsTextObject = res.renderer
            .text(
              startDate + ' - ' + endDate,
              textPositionX,
              res.navigator.handles[0].translateY - 15,
            )
            .css({ color: color, fontSize: 12 })
            .add();
        },
        false,
      );
      navContainer.addEventListener(
        'mouseleave',
        () => {
          if (
            highchartsTextObject &&
            !(Object.keys(highchartsTextObject).length === 0)
          ) {
            highchartsTextObject.destroy();
          }
        },
        false,
      );
    });
    this.loading = false;
  }
}
