/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { Observable, of, forkJoin } from 'rxjs';
import { YukkApi } from 'src/app/service/yukkapi.service';
import { catchError, map, startWith } from 'rxjs/operators';
import { Sort } from '@angular/material/sort';
import * as XLSX from 'xlsx';

@Component({
  encapsulation: ViewEncapsulation.None,
  selector: 'app-stats',
  templateUrl: './stats.component.html',
  styleUrls: ['./stats.component.scss'],
})
export class StatsComponent implements OnInit {
  companies = [];
  filteredCompanies = [];

  countries = [];
  selectedCountry: any;
  myControlCountries = new UntypedFormControl();
  filteredCountries: Observable<any>;

  events = [];
  selectedEvents = [];

  dataSource: any;
  displayedColumns: string[] = [
    'name',
    'industry',
    'ceo',
    'sentiment',
    'sentiment_delta',
    'volume',
    'volume_ratio',
  ];

  constructor(private yukkApi: YukkApi) {}

  ngOnInit(): void {
    this.dataSource = [];
    this.yukkApi.getCountries().subscribe((res) => {
      this.countries = res.entities
        .filter((el) => el.name)
        .sort((a, b) => a.name.localeCompare(b.name));
      this.filteredCountries = this.myControlCountries.valueChanges.pipe(
        startWith(''),
        map((value) => (typeof value === 'string' ? value : value.name)),
        map((name) =>
          name
            ? this.elementMatches(this.countries, name)
            : this.countries.slice(),
        ),
      );
    });
    this.yukkApi.getEventsTreemap().subscribe((res) => {
      this.events = res.treemap.children;
    });
  }

  displayFn(element) {
    return element && element.name ? element.name : '';
  }

  elementMatches(options, value) {
    const filterValue = value.toLowerCase();
    return options.filter((item) =>
      item.name.toLowerCase().includes(filterValue),
    );
  }

  selectCountry(item) {
    this.dataSource = null;
    this.yukkApi
      .treemap({
        time: 90,
        type: 'country',
        id: item.alpha_id,
      })
      .subscribe((res) => {
        this.companies = res.treemap.children;
        const companies = this.companies.map((el) => {
          return el.entity.compound_key;
        });
        if (companies.length > 0) {
          if (this.selectedEvents && this.selectedEvents.length > 0) {
            const events = this.selectedEvents.map((el) => {
              return el.id;
            });
            const content = companies;
            const requestsList = [];
            const chunkSize = 100;
            for (let i = 0; i < content.length; i += chunkSize) {
              requestsList.push(
                this.yukkApi
                  .relatedEvents(
                    {
                      time: 90,
                      event_ids: events,
                      body: content.slice(i, i + chunkSize),
                    },
                    'sub_events_itemized',
                  )
                  .pipe(
                    catchError(() => {
                      return of(
                        Array(
                          content.length - i < chunkSize
                            ? content.length - i
                            : chunkSize,
                        ).fill({ result: [] }),
                      );
                    }),
                  ),
              );
            }
            forkJoin(requestsList).subscribe((res3: any[]) => {
              const res2 = res3.flat();
              const filteredCompanies = [];
              res2.forEach((el, index) => {
                if (el.result && el.result.length > 0) {
                  let isParticipant = false;
                  el.result.forEach((event) => {
                    if (event.count > 0 && events.includes(event.event.id)) {
                      isParticipant = true;
                    }
                  });
                  if (isParticipant) {
                    filteredCompanies.push(this.companies[index]);
                  }
                }
              });
              this.filteredCompanies = filteredCompanies;
              this.dataSource = filteredCompanies.map((el) => {
                return {
                  name: el.entity.name,
                  industry: el.entity.description.Industry?.name,
                  ceo: el.entity.description.CEO?.name,
                  sentiment: el.sentiment,
                  sentiment_delta: el.sentiment_delta,
                  volume: el.count,
                  volume_ratio: el.volume_ratio,
                };
              });
            });
          } else {
            this.filteredCompanies = res.treemap.children;
            this.dataSource = res.treemap.children.map((el) => {
              return {
                name: el.entity.name,
                industry: el.entity.description.Industry?.name,
                ceo: el.entity.description.CEO?.name,
                sentiment: el.sentiment,
                sentiment_delta: el.sentiment_delta,
                volume: el.count,
                volume_ratio: el.volume_ratio,
              };
            });
          }
        } else {
          this.dataSource = [];
        }
      });
  }

  selectEvents(opened) {
    if (!opened) {
      if (this.companies && this.companies.length > 0) {
        this.dataSource = null;
        const events = this.selectedEvents.map((el) => {
          return el.id;
        });
        const content = this.companies.map((el) => {
          return el.entity.compound_key;
        });
        const requestsList = [];
        const chunkSize = 100;
        for (let i = 0; i < content.length; i += chunkSize) {
          requestsList.push(
            this.yukkApi
              .relatedEvents(
                {
                  time: 90,
                  event_ids: events,
                  body: content.slice(i, i + chunkSize),
                },
                'sub_events_itemized',
              )
              .pipe(
                catchError(() => {
                  return of(
                    Array(
                      content.length - i < chunkSize
                        ? content.length - i
                        : chunkSize,
                    ).fill({ result: [] }),
                  );
                }),
              ),
          );
        }
        forkJoin(requestsList).subscribe((res3: any[]) => {
          const res2 = res3.flat();
          const filteredCompanies = [];
          res2.forEach((el, index) => {
            if (el.result && el.result.length > 0) {
              let isParticipant = false;
              el.result.forEach((event) => {
                if (event.count > 0 && events.includes(event.event.id)) {
                  isParticipant = true;
                }
              });
              if (isParticipant) {
                filteredCompanies.push(this.companies[index]);
              }
            }
          });
          this.filteredCompanies = filteredCompanies;
          this.dataSource = filteredCompanies.map((el) => {
            return {
              name: el.entity.name,
              industry: el.entity.description.Industry?.name,
              ceo: el.entity.description.CEO?.name,
              sentiment: el.sentiment,
              sentiment_delta: el.sentiment_delta,
              volume: el.count,
              volume_ratio: el.volume_ratio,
            };
          });
        });
      }
    }
  }

  inFileExport() {
    if (this.dataSource && this.dataSource.length > 0) {
      const date = new Date();
      const displayDate =
        date.getFullYear() +
        '-' +
        date.getMonth() +
        '-' +
        date.getDate() +
        ' ' +
        date.getHours() +
        '-' +
        date.getMinutes() +
        '-' +
        date.getSeconds();

      const doubleArrayData = [
        ['Name', 'Industry', 'CEO', 'Sent', 'ΔSent', 'Vol', 'ΔVol'],
      ];

      this.dataSource.forEach((company) => {
        doubleArrayData.push([
          company.name ? company.name : '',
          company.industry ? company.industry : '',
          company.ceo ? company.ceo : '',
          company.sentiment ? company.sentiment : '',
          company.sentiment_delta ? company.sentiment_delta : '',
          company.volume ? company.volume : '',
          company.volume_ratio ? company.volume_ratio : '',
        ]);
      });

      /* generate worksheet */
      const ws: XLSX.WorkSheet = XLSX.utils.aoa_to_sheet(doubleArrayData);
      /* generate workbook and add the worksheet */
      const wb: XLSX.WorkBook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
      /* save to file */
      XLSX.writeFile(wb, 'Statistics from ' + displayDate + '.xlsx');
    }
  }

  sortData(sort: Sort) {
    const data = this.dataSource.slice();
    if (!sort.active || sort.direction === '') {
      this.dataSource = data;
      return;
    }

    this.dataSource = data.sort((a, b) => {
      const isAsc = sort.direction === 'asc';
      switch (sort.active) {
        case 'name':
          return this.compare(a.name, b.name, isAsc);
        case 'industry':
          return this.compare(a.industry, b.industry, isAsc);
        case 'ceo':
          return this.compare(a.ceo, b.ceo, isAsc);
        case 'sentiment':
          return this.compare(a.sentiment, b.sentiment, isAsc);
        case 'sentiment_delta':
          return this.compare(a.sentiment_delta, b.sentiment_delta, isAsc);
        case 'volume':
          return this.compare(a.volume, b.volume, isAsc);
        case 'volume_ratio':
          return this.compare(a.volume_ratio, b.volume_ratio, isAsc);
        default:
          return 0;
      }
    });
  }

  compare(a: number | string, b: number | string, isAsc: boolean) {
    return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
  }
}
