/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  Component,
  OnInit,
  Input,
  OnChanges,
  ViewEncapsulation,
} from '@angular/core';
import { QueryEditorComponent } from '../query-editor.component';
import { UntypedFormControl, UntypedFormArray } from '@angular/forms';
import { Observable } from 'rxjs';
import { debounceTime, map } from 'rxjs/operators';
import { YukkApi } from 'src/app/service/yukkapi.service';
import { AuthService } from 'src/app/service/auth.service';
import { ActivatedRoute } from '@angular/router';
import { SourceSearchItem } from 'src/app/shared/models/search-results.interface';

@Component({
  encapsulation: ViewEncapsulation.None,
  selector: 'app-query-editor-search',
  templateUrl: './query-editor-search.component.html',
  styleUrls: ['./query-editor-search.component.scss'],
})
export class QueryEditorSearchComponent implements OnInit, OnChanges {
  mySearch: UntypedFormControl;
  @Input() data: any;
  selectedArtifact: any;
  items$: Observable<{
    entity_prefix_search: { hits: any[] };
    event_search?: { hits: any[] };
    source_prefix_search: { hits: SourceSearchItem[] };
  }>;
  filteredSourcesResult$: SourceSearchItem[] = [];
  sourcesResult$: SourceSearchItem[] = [];
  folio$: Observable<any>;
  query$: Observable<any>;
  folioArray: any;
  queryArray: any;
  params: any;
  name: string;
  value: string;
  input: string;
  error: boolean;
  label = 'Search';
  entity: boolean;
  oldOperator: string;

  constructor(
    public query: QueryEditorComponent,
    private yukkApi: YukkApi,
    public auth: AuthService,
    private route: ActivatedRoute,
  ) {
    this.route.queryParams.subscribe((params) => {
      this.params = params;
    });
  }

  ngOnInit() {
    this.folio$ = this.auth.portFolios();
    this.query$ = this.auth.getQuery();
    this.folio$.subscribe((result) => {
      this.folioArray = result;
    });
    this.query$.subscribe((result) => {
      this.queryArray = result;
    });
    this.oldOperator = this.data.operator;
    if (this.data.name) {
      this.mySearch = new UntypedFormControl(this.data.name);
    } else {
      this.mySearch = new UntypedFormControl(this.data.value);
    }
    if (['query', 'portfolio'].includes(this.data.field)) {
      this.label = this.data.field;
      this.selectedArtifact = this.data.value;
    } else if (this.data.value === '') {
      this.label = '';
    } else {
      if (
        Object.prototype.hasOwnProperty.call(this.data, 'field') &&
        this.data.field !== ''
      ) {
        if (this.data.field === 'entity') {
          if (this.data.value.split(':')[1]) {
            this.label = this.data.value.split(':')[0];
          }
        } else if (
          this.data.field === 'super_event' ||
          this.data.field === 'event' ||
          this.data.field === 'sub_event'
        ) {
          this.label = 'Event';
        } else if (this.data.field === 'word') {
          this.label = 'Text';
        } else {
          this.label = this.data.field;
        }
      } else {
        this.label = '';
      }
    }

    this.mySearch.valueChanges.pipe(debounceTime(1000)).subscribe((value) => {
      this.initSearch(value);
    });
  }

  ngOnChanges() {
    if (
      this.oldOperator !== this.data.operator &&
      (this.oldOperator === 'near' || this.data.operator === 'near')
    ) {
      this.label = 'Text';
      if (this.data && this.data.input !== null) {
        this.mySearch = new UntypedFormControl(this.data.input.trim());
      } else {
        this.mySearch = new UntypedFormControl('');
      }
      this.mySearch.valueChanges.pipe(debounceTime(1000)).subscribe((value) => {
        this.initSearch(value);
      });
    }
    this.oldOperator = this.data.operator;
  }

  initSearch(value) {
    if (!this.entity) {
      // this.label = 'Text';
      if (this.data.level === 0) {
        const rule = this.query.queryForm.get('filters') as UntypedFormArray;
        rule.controls[this.data.index].get('name').setValue('');
        rule.controls[this.data.index].get('value').setValue(value);
        if (typeof value !== 'object') {
          rule.controls[this.data.index].get('input').setValue(value);
        }
        rule.controls[this.data.index].get('field').setValue('text');
      } else {
        const groups = this.query.queryForm.get('groups') as UntypedFormArray;
        const filters = groups
          .at(this.data.groups)
          .get('filters') as UntypedFormArray;
        filters.controls[this.data.index].get('name').setValue('');
        filters.controls[this.data.index].get('value').setValue(value);
        if (typeof value !== 'object') {
          filters.controls[this.data.index].get('input').setValue(value);
        }
        if (this.data.operator === 'near') {
          filters.controls[this.data.index].get('field').setValue('word');
        } else {
          filters.controls[this.data.index].get('field').setValue('text');
        }
      }
    }
    this.entity = false;
    if (value && value.length > 1) {
      this.items$ = this.yukkApi.search_meta(value, this.params).pipe(
        map((result) => {
          this.setSourcesResult(result.source_prefix_search.hits);

          return result;
        }),
      );
    }
  }

  setSourcesResult(result: SourceSearchItem[]) {
    this.filteredSourcesResult$ = result?.filter((item) => item.is_filtered);
    this.sourcesResult$ = result?.filter((item) => !item.is_filtered);
  }

  goSelect(option) {
    if (option.type === 'freetext') {
      this.label = 'Text';
      this.mySearch.setValue(option.name);
      const groups = this.query.queryForm.get('groups') as UntypedFormArray;
      const filters = groups
        .at(this.data.groups)
        .get('filters') as UntypedFormArray;
      filters.controls[this.data.index].get('field').setValue('text');
    } else if (option.type === 'word') {
      this.label = 'Text';
      this.mySearch.setValue(option.name);
      const groups = this.query.queryForm.get('groups') as UntypedFormArray;
      const filters = groups
        .at(this.data.groups)
        .get('filters') as UntypedFormArray;
      filters.controls[this.data.index].get('field').setValue('word');
    } else if (option.type === 'source') {
      this.label = 'Source';
      this.entity = true;
      const groups = this.query.queryForm.get('groups') as UntypedFormArray;
      const filters = groups
        .at(this.data.groups)
        .get('filters') as UntypedFormArray;
      filters.controls[this.data.index]
        .get('name')
        .setValue(option.option.source_url);
      filters.controls[this.data.index]
        .get('value')
        .setValue(option.option.source_url);
      filters.controls[this.data.index].get('field').setValue('source');
      this.mySearch.setValue(option.option.source_url);
    } else if (option.type === 'source_url') {
      this.label = 'Source URL';
      this.entity = true;
      this.mySearch.setValue(option.name);
      const groups = this.query.queryForm.get('groups') as UntypedFormArray;
      const filters = groups
        .at(this.data.groups)
        .get('filters') as UntypedFormArray;
      filters.controls[this.data.index].get('name').setValue(option.name);
      filters.controls[this.data.index].get('value').setValue(option.name);
      filters.controls[this.data.index].get('field').setValue('source_url');
    } else if (option.type === 'event') {
      this.label = 'Event';
      this.entity = true;
      const groups = this.query.queryForm.get('groups') as UntypedFormArray;
      const filters = groups
        .at(this.data.groups)
        .get('filters') as UntypedFormArray;
      filters.controls[this.data.index]
        .get('name')
        .setValue(option.option.name);
      filters.controls[this.data.index].get('value').setValue(option.option.id);
      filters.controls[this.data.index]
        .get('field')
        .setValue(option.option.type);
      this.mySearch.setValue(option.option.name);
    } else {
      const name = option.entity.name;
      const keyd = option.entity.type + ':' + option.entity.alpha_id;
      this.label = option.entity.type;
      this.entity = true;
      if (this.data.level === 0) {
        const rule = this.query.queryForm.get('filters') as UntypedFormArray;
        rule.controls[this.data.index].get('name').setValue(name);
        rule.controls[this.data.index].get('value').setValue(keyd);
        rule.controls[this.data.index].get('field').setValue('entity');
      } else {
        const groups = this.query.queryForm.get('groups') as UntypedFormArray;
        const filters = groups
          .at(this.data.groups)
          .get('filters') as UntypedFormArray;
        filters.controls[this.data.index].get('name').setValue(name);
        filters.controls[this.data.index].get('value').setValue(keyd);
        filters.controls[this.data.index].get('field').setValue('entity');
      }
      this.mySearch.setValue(name);
    }
  }

  selectArtifact(option, type) {
    let selectedOption = null;
    if (type === 'portfolio') {
      selectedOption = this.folioArray.filter((o) => o.uid === option)[0];
    } else if (type === 'query') {
      selectedOption = this.queryArray.filter((o) => o.uid === option)[0];
    }
    const name = selectedOption.name;
    const keyd = selectedOption.uid;
    this.label = type;
    this.entity = true;
    if (this.data.level === 0) {
      const rule = this.query.queryForm.get('filters') as UntypedFormArray;
      rule.controls[this.data.index].get('name').setValue(name);
      rule.controls[this.data.index].get('input').setValue(name);
      rule.controls[this.data.index].get('value').setValue(keyd);
      rule.controls[this.data.index].get('field').setValue(this.label);
    } else {
      const groups = this.query.queryForm.get('groups') as UntypedFormArray;
      const filters = groups
        .at(this.data.groups)
        .get('filters') as UntypedFormArray;
      filters.controls[this.data.index].get('name').setValue(name);
      filters.controls[this.data.index].get('input').setValue(name);
      filters.controls[this.data.index].get('value').setValue(keyd);
      filters.controls[this.data.index].get('field').setValue(this.label);
    }
  }

  inBlur(value) {
    this.initSearch(value);
  }

  onSearchChange(value) {
    if (value === '') {
      this.label = '';
    } else {
      this.label = 'Text';
    }
  }

  /**
   *
   */
  iMatch(entity) {
    if (entity.matched_form) {
      return (
        entity.name.charAt(0).toLowerCase() !== entity.matched_form.charAt(0)
      );
    }
  }

  /**
   * format big numbers >999 into K, M, ...
   */
  numberFormat(num: number) {
    const formatter = new Intl.NumberFormat('en', { notation: 'compact' });
    return formatter.format(num);
  }

  queryNameFromId(id: string) {
    if (id && this.queryArray && this.queryArray.length > 0) {
      return this.queryArray.filter((o) => o.uid === id)[0].name;
    }
    return '';
  }

  isUserAdmin() {
    return (
      this.auth.userdata.roles?.includes('ADMIN') ||
      this.auth.userdata.groups?.includes('ADMIN')
    );
  }
}
